@import 'asset/scss/_themes-vars.module.scss';

.side-menu-icon-list {
    // svg {
    //     width: '3rem';
    //     height: '3rem' !important;
    // }
}

// .active-side-menu::before {
//     content: '';
//     position: absolute;
//     background: $primary;
//     border-radius: 0px 6px 6px 0px;
//     width: 6px;
//     height: 100%;
//     bottom: 0;
//     left: 0px;
// }

.v-name {
    color: #283891;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.v-version {
    color: #4091D6;
    margin-top: 5px;
}

.v-name1 {
    font-size: .8rem;
    font-weight: 500;
    margin: 0;
}

.v-version1 {
    opacity: .8;
    font-size: .8rem;
    // margin-top: 5px;
}