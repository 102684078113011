// paper & backgrounb
$paper: #ffffff;
$main_div: #eef2f9;

// Text Color
$textDark: #33414d;
$textMid: #616b73;
$textLight: #818c94;
$textDisable: #bcc7d0;
$textInverse: #ffffff;
$textLink: #b15e0b;
$textInfo: #005095;
$textWarning: #ff9900;
$textError: #f40000;
$textSuccess: #00b066;
$textBtn: #15171e;
$textOrange: #ab5b0b;
$textGrey: #616b73;
$textLightGrey: #5c5c5c;

// Background
$backgroundBlueLight: #f0f8ff;
$backgroundBlueMid: #e2f2ff;
$backgroundSkyBlueLight: #f6fafe;
$backgroundOrangeLight: #fff8ee;
$backgroundOrangeMid: #fff1de;
$backgroundGrey: #2d4355;
$backgroundLightGreen: #00b066;

$backgroundLinelight: #e3e8ef;
$backgroundDarkGrey: #192732;
$backgroundDarkBlue: #174072;
$backgroundYellowLight: #fcffdf;
$backgroundYellowMid: #faffca;

// Border Colors
$borderLinelight: #e3e8ef;
$borderLineMid: #d8dde5;
$borderOutline: #d0d5dd;
$borderOutlineHover: #b3b5b7;
$borderOutlineFocus: #b4ceed;

// Box Shadow
$boxShadowLight: #33414d1a;
$boxShadowMid: #33414d33;

// Supportive color
$supportiveYellow: #e89929;
$supportiveSkyBlue: #34aaff;
$supportiveGrey: #6287a4;

// Toaster Color
$toasterError: #f40000;
$toasterSuccess: #00b066;

// IconColor
$iconGreyDark: #274054;
$iconGreyLight: #7395af;
$iconOrange: #ab5b0b;
$iconInfo: #005095;
$iconWarning: #ff9900;
$iconError: #f40000;
$iconSuccess: #00b066;

// Chips
$chipGrey: #f0f4fa;
$chipOrange: #fff6ed;
$chipOrange: #ffeeee;
$chipyellow: #fffcde;
$chipBlue: #e4f3ff;
$chipGreen: #e2fff3;

// Button

$buttonOrange: #ff7e00;
$buttonHover: #e87300;
$buttonDisable: #d0d5dd;

$primary: #007bffe9;
$primaryLight: #eef2f6;
$primaryMain: #ff7e00;
$primaryDark: rgb(42, 42, 138);
$primary200: #90caf9;
$primary700: #0090d6;
$primary800: #1565c0;
$createButtonColor: #5cb85c;
$tableHead: #666666;
$tableHeadText: #666666;
$buttonColor: #283891;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #dcdbdf;
$secondaryDark: #b8b6bd;
$secondary200: #b39ddb;
$secondary800: #4527a0;
$defaultBorderColor: #ccc;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #eef2f6;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;
$headingText: #324254;

$sideMenuSelectIconColor: #007bff;
$sideMenuSelectIconColorBack: #007bff;
$avtarBGColor: #8353e2;
$inputBoxBgColor: #0000000f;
$tableHeadBackground: #f2f6fc;
// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;
  main_div: $main_div;

  textDark: $textDark;
  textMid: $textMid;
  textLight: $textLight;
  textDisable: $textDisable;
  textInverse: $textInverse;
  textLink: $textLink;
  textInfo: $textInfo;
  textWarning: $textWarning;
  textOrange: $textOrange;
  textGrey: $textGrey;
  textError: $textError;
  textSuccess: $textSuccess;
  textBtn: $textBtn;
  textLightGrey: $textLightGrey;

  // Background
  backgroundBlueLight: $backgroundBlueLight;
  backgroundBlueMid: $backgroundBlueMid;
  backgroundSkyBlueLight: $backgroundSkyBlueLight;
  backgroundOrangeLight: $backgroundOrangeLight;
  backgroundOrangeMid: $backgroundOrangeMid;
  backgroundGrey: $backgroundGrey;
  backgroundDarkGrey: $backgroundDarkGrey;
  backgroundLightGreen: $backgroundLightGreen;
  backgroundDarkBlue: $backgroundDarkBlue;
  backgroundYellowLight: $backgroundYellowLight;
  backgroundYellowMid: $backgroundYellowMid;
  backgroundLinelight: $backgroundLinelight;

  backgroundLinelight: $backgroundLinelight;
  backgroundLightGreen: $backgroundLightGreen;

  // Box Shadow Colors
  boxShadowLight: $boxShadowLight;
  boxShadowMid: $boxShadowMid;

  // success
  borderLinelight: $borderLinelight;
  borderLineMid: $borderLineMid;
  borderOutline: $borderOutline;
  borderOutlineHover: $borderOutlineHover;
  borderOutlineFocus: $borderOutlineFocus;
  // error
  supportiveYellow: $supportiveYellow;
  supportiveSkyBlue: $supportiveSkyBlue;
  supportiveGrey: $supportiveGrey;

  // Toaster
  toasterError: $toasterError;
  toasterSuccess: $toasterSuccess;

  // ICon
  iconGreyDark: $iconGreyDark;
  iconGreyLight: $iconGreyLight;
  iconOrange: $iconOrange;
  iconInfo: $iconInfo;
  iconWarning: $iconWarning;
  iconError: $iconError;
  iconSuccess: $iconSuccess;

  // Chip
  chipGrey: $chipGrey;
  chipOrange: $chipOrange;
  chipOrange: $chipOrange;
  chipyellow: $chipyellow;
  chipBlue: $chipBlue;
  chipGreen: $chipGreen;

  // Button

  buttonOrange: $buttonOrange;
  buttonHover: $buttonHover;
  buttonDisable: $buttonDisable;

  // primary
  primary: $primary;
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary700: $primary700;
  primary800: $primary800;
  createButtonColor: $createButtonColor;
  tableHead: $tableHead;
  tableHeadText: $tableHeadText;
  buttonColor: $buttonColor;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;
  defaultBorderColor: $defaultBorderColor;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;
  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;
  headingText: $headingText;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;

  sideMenuSelectIconColor: $sideMenuSelectIconColor;
  sideMenuSelectIconColorBack: $sideMenuSelectIconColorBack;
  avtarBGColor: $avtarBGColor;
  inputBoxBgColor: $inputBoxBgColor;

  // addButtonColor: $addButtonColor;
}
