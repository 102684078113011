.swal2-confirm {
  background-color: #ff7e00 !important;
  border-radius: 48px !important;
  padding: 12px, 24px, 12px, 24px;
  height: 48px !important;
  font-size: 1rem;
  font-weight: 600;
  color: #15171e !important;
}

.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid #ff7e00 !important;
  border-radius: 48px !important;
  padding: 12px, 24px, 12px, 24px;
  height: 48px !important;
  font-size: 1rem;
  font-weight: 600;
  color: #15171e !important;
  order: 1;
}

.swal2-title {
  line-height: 2rem;
}

.swal2-html-container {
  margin: 1em 1.6em 1em 1.6em !important;
}
