.loader-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 250px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #333333a3;
  animation: bounce 0.6s infinite alternate;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-12px);
  }
}
@media (max-width: 500px) {
  .dot {
    width: 7px;
    height: 7px;
  }
}
