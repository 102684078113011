.slider-container {
  .slick-dots li.slick-active {
    width: 35px;
  }

  .slick-dots li.slick-active button {
    width: 35px;
  }

  .slick-dots li button:before {
    color: #ff7e00;
  }

  .slick-dots li.slick-active button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 5px;
    content: "";
    text-align: center;
    //opacity: 0.25;
    color: #ff7e00;
    background-color: #ff7e00; /* optional: set a background color */
    display: block;
    margin-top: 8px;
  }

  /* Set width and height of the pseudo-element */
  .slick-dots li.slick-active button:before {
    width: 35px; /* Adjust width as needed */
  }

  /* If you want to maintain the circle shape, you can use border-radius */
  .slick-dots li.slick-active button:before {
    border-radius: 15px; /* Make it a circle */
  }

  .slick-prev {
    left: 38%;
    top: unset;
    transform: unset;
    bottom: -25px;
    border: 1px solid #ebeced;
  }
}

.slider-container.vertical {
  .slick-dots li.slick-active {
    height: 35px;
  }
  .slick-dots li.slick-active button {
    height: 35px;
  }
  .slick-dots li.slick-active button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 5px;
    height: 5px;
    content: "";
    text-align: center;
    color: #ff7e00;
    background-color: #ff7e00; /* optional: set a background color */
    display: block;
    margin-top: 0px !important;
    margin-left: 8px;
  }

  .slick-dots li.slick-active button:before {
    height: 35px;
  }
  .slick-dots {
    display: flex !important;
    flex-direction: column;
    left: -30px;
    top: 0;
    transform: translate(0, 50%);
    bottom: unset;
  }
}
