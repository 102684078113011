

@import "../../asset/scss/themes-vars.module";

.showIcon {
  visibility: hidden !important;
}

.tableheading {
  color: $tableHeadText;
  padding-left: 15px;
  background-color: $backgroundBlueMid;
}
.filterimage {
  width: 8px;
  margin-left: 5px;
}
.MuiTableSortLabel-icon {
  display: none;
}
.MuiTableSortLabel-root {
  font-weight: 600;
  color: #15171e;
  font-size: 1rem;
}
.css-1d1tm32-MuiTableRow-root.MuiTableRow-hover:hover {
  .showIcon {
    visibility: visible !important;
  }
}
.table-container::-webkit-scrollbar {
  width: 5px;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: rgb(203, 200, 200);

  border-radius: 16px;
  margin-top: 10px;
}
.table-container {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 17px 18px -25px;

  border-radius: 8px;

  border: 1px solid rgb(212, 211, 211);
}
.bold {
  font-weight: 700;
}
